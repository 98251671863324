import React, { FC, useEffect, useState } from 'react';
import { User } from 'store/types/User';
import edit from '../../image/edit.svg';
import gold from '../../image/icons/activity/collega-gold.svg';
import SkillProfileItem from './SkillProfileItem';
import _ from 'lodash';
import { FormFields, SubscriptionType } from 'values/values';
import strings from 'values/strings';
import UserService from 'services/UserServices';
import InputAutosuggest from '../common/AutoSuggest'
import { useDispatch, useSelector } from 'react-redux';
import { createTempUser, IUserReducer, updateTempUser } from 'store/reducers/UserReducer';
import toast from 'react-hot-toast';


type Props = {
    currentUser?: User;
    onPress?: () => void
    onEdit?: () => void
}

const ProfessionalSkill: FC<Props> = (props) => {

    const [citiesResponse, , citiesRequest] = UserService.useSearchCitiesAPI();
    const currentUser = useSelector((state: IUserReducer) => state.user.currentUser);
    let [updateResponse, updateError, updateRequest] = UserService.useUpdateUserMeAPI()
    const tempUser = useSelector((state: IUserReducer) => state.user.tempUser);
    const [canSave, setCanSave] = useState<boolean>(false)
    const [lawfirmCity2, setLawfirmCity2] = useState<any>({
        query: currentUser && currentUser!.profile.lawfirm.city2 != undefined ? currentUser.profile.lawfirm.city2.display_name : '',
        object: currentUser && currentUser!.profile.lawfirm.city2 ? currentUser.profile.lawfirm.city2 : undefined
    })
    const professionalSkillsSubscription = User.professionalSubscription(props.currentUser);
    const dispatch = useDispatch()


    useEffect(() => {
        dispatch(createTempUser(currentUser))
    }, [])

    useEffect(() => {
        if (updateResponse) toast.success("Secondo comune aggiornato!")
    }, [updateResponse])

    useEffect(() => {
        if (canSave) {
            updateRequest(tempUser!)
        }
    }, [canSave])

    const availableProfessionalSkills = () => {
        const { currentUser } = props;

        // Check if currentUser and profile exist
        if (!currentUser || !currentUser.profile) {
            return 0; // Return 0 if currentUser is undefined
        }

        const professionalSkills = currentUser.profile.skills.filter((item) => item.type === SubscriptionType.PROFESSIONAL.toUpperCase()) || [];

        // Get the professional subscription safely
        const subscription = User.professionalSubscription(currentUser);

        // Check if subscription exists before accessing purchased_skills
        const availableProfessionallSkills = subscription ? subscription.purchased_skills! - professionalSkills.length : 0;

        return availableProfessionallSkills;
    };

    const professionalSkills = () => {
        if (props.currentUser === undefined) { return [] }
        return props.currentUser.profile.skills.filter((item) => item.type === SubscriptionType.PROFESSIONAL.toUpperCase());
    };

    let updateProfile = async (field: string, value: any) => {
        let data: any = {
            ...tempUser!.profile,
            [field]: value
        }

        await dispatch(updateTempUser(FormFields.profile, data))
    }

    let updateLegalOffice = async (field: string, value: any) => {
        let data = {
            ...tempUser!.profile.lawfirm,
            [field]: value
        }

        await updateProfile(FormFields.lawfirm, data)
    }

    return (
        <>

            <div className="specific-container">
                <div className="specific-header">
                    {/* Left side (Icon and text) */}
                    <div className="left-content">
                        <img src={gold} alt="collega" style={{ marginRight: '8px', width: 20, height: 20 }} />
                        <span>Collega professional</span>
                    </div>

                    {/* Right side (Edit icon) TODO: Handle renewed logic */}
                    {professionalSkillsSubscription && professionalSkillsSubscription.renewed && <img
                        src={edit}
                        alt="edit-icon"
                        onClick={props.onEdit}
                    />}
                </div>

                <div className='text-certified-container'>
                    <span className='text-certified'>Il secondo comune in cui operi è:</span>
                </div>

                <div className='text-certified-container'>
                    <form className="full-width-form">
                        <InputAutosuggest
                            hasIcon={false}
                            placeholder={strings.profileEdit.step.lawfirm.input.city2.placeholder}
                            suggestions={citiesResponse || []}
                            onChange={(v) => {
                                setLawfirmCity2({ query: v, object: undefined });
                            }}
                            onSelect={(data) => {
                                updateLegalOffice(FormFields.city2, data.value);
                                setCanSave(true);
                            }}
                            value={lawfirmCity2.query}
                            onFetch={citiesRequest}
                        />
                    </form>
                </div>


                <div className={'secondary-divider'}></div>

                <div>
                    {/* Show additional skill */}
                    {professionalSkills().map((skill, index, array) => {
                        const isLast = index === array.length - 1;
                        return (
                            <div key={_.uniqueId()}>
                                <div style={{ flex: 1 }}>
                                    <SkillProfileItem
                                        skill={skill}
                                        isGold={User.isGold(props.currentUser)}
                                        onPress={props.onPress}
                                    />
                                </div>

                                {!isLast && <div className='third-divider' />}
                            </div>

                        );
                    })}
                    {availableProfessionalSkills() != undefined && availableProfessionalSkills()! > 0 && (
                        <p className='text'>
                            Puoi aggiungere ancora {availableProfessionalSkills()}{' '}
                            {availableProfessionalSkills() === 1 ? 'competenza' : 'competenze'}.
                        </p>
                    )}

                    {availableProfessionalSkills() != undefined && availableProfessionalSkills()! > 0 && <div className={'button__'}>
                        <button
                            className={'button-secondary'}
                            onClick={props.onPress}
                        >{props.currentUser!.profile.skills.filter((item) => item.is_main === false).length < 3
                            ? 'Seleziona competenze'
                            : 'Aggiungi competenze'}</button>
                    </div>}
                </div>
            </div>

        </>
    );
};

export default ProfessionalSkill;




