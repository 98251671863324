import React, { useEffect, useState } from "react";

import "react-credit-cards-2/dist/es/styles-compiled.css";
import PaymentButton from "./PaymentButton";

import { FormFields, provinces } from "values/values";
import SelectOption from "store/types/SelectionOption";
import Select from "components/common/Select";

interface Props {
  onClose: () => void
  onPay: (formData: any) => void
}
const CreditCardForm: React.FC<Props> = ({ onPay, onClose }) => {


  const [errors, setErrors] = useState<any>()
  const [formData, setFormData] = useState({
    ccname: "",
    cardNumber: "",
    expirationDate: "",
    address1: "",
    address2: "",
    postalCode: "",
    province: "",
    province_short: "FR",
    country_code: "IT",
  });


  useEffect(() => {
    if (!errors) return

    if (errors && Object.keys(errors).length === 0) {
      // Invia i dati se non ci sono errori
      console.log("Form valido, invio dati...", formData);
      onPay(formData)
    } else {
      // Gestisci gli errori
      console.log("Errori di validazione", errors);
    }
  }, [errors])


  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e: any) => {
    setErrors(validateForm(formData))
  };


  const validateForm = (formData: any) => {
    const errors: any = {};

    // Numero carta di credito: obbligatorio, 16 cifre
    const cardNumberOnlyDigits = formData.cardNumber.replace(/\s+/g, ''); // Rimuove spazi
    const issuer = detectCardIssuer(cardNumberOnlyDigits);

    if (issuer === "amex") {
      // American Express: 15 cifre
      if (!/^\d{15}$/.test(cardNumberOnlyDigits)) {
        errors.cardNumber = "Il numero della carta American Express deve essere composto da 15 cifre";
      }
    } else if (issuer === "dinersclub") {
      // Diners Club: 14 cifre
      if (!/^\d{14}$/.test(cardNumberOnlyDigits)) {
        errors.cardNumber = "Il numero della carta Diners Club deve essere composto da 14 cifre";
      }
    } else if (issuer === "visa" || issuer === "mastercard" || issuer === "discover") {
      // Visa, MasterCard, Discover: 16 cifre
      if (!/^\d{16}$/.test(cardNumberOnlyDigits)) {
        errors.cardNumber = "Il numero della carta deve essere composto da 16 cifre";
      }
    } else {
      errors.cardNumber = "Tipo di carta non riconosciuto";
    }

    // Data di scadenza: obbligatoria, formato MM/YY e deve essere una data futura
    const expirationDateRegex = /^(0[1-9]|1[0-2])\/([0-9]{2})$/;
    if (!formData.expirationDate || !expirationDateRegex.test(formData.expirationDate)) {
      errors.expirationDate = "La data di scadenza deve essere nel formato MM/YY";
    } else {
      const [month, year] = formData.expirationDate.split('/');
      const expiration = new Date(Number(`20${year}`), month); // Costruisce la data di scadenza
      const now = new Date();

      if (expiration < now) {
        errors.expirationDate = "La carta è scaduta";
      }
    }

    // Indirizzo: obbligatorio
    if (!formData.address1) {
      errors.address1 = "L'indirizzo è obbligatorio";
    }

    // CAP: obbligatorio, formato 5 cifre
    const postalCodeRegex = /^[0-9]{5}$/;
    if (!formData.postalCode || !postalCodeRegex.test(formData.postalCode)) {
      errors.postalCode = "Il CAP deve essere composto da 5 cifre";
    }

    // Provincia: obbligatoria
    if (!formData.province) {
      errors.province = "La provincia è obbligatoria";
    }

    // // SDI: obbligatorio, 7 caratteri alfanumerici
    // const sdiRegex = /^[A-Za-z0-9]{7}$/;
    // if (!formData.sdi || !sdiRegex.test(formData.sdi)) {
    //   errors.sdi = "Il codice SDI deve essere composto da 7 caratteri alfanumerici";
    // }

    return errors;
  };



  return (
    <div key="Payment">
      <div className="credit-card-form">

        <form onSubmit={handleSubmit}>
          <h3>Paga con carta di credito</h3>

          <div className="form-group">
            <input
              type="text"
              name="ccname"
              className="form-control"
              placeholder="Nome completo (opzionale)"
              autoComplete="cc-name"
              required
              onChange={handleInputChange}
            />
          </div>

          <div className="form-group">
            <input
              type="text"
              name="cardNumber"
              className="form-control"
              placeholder="Numero carta"
              pattern="[\d| ]{16,22}"
              autoComplete="cc-number"
              required
              value={formatCreditCardNumber(formData.cardNumber)}
              onChange={handleInputChange}
            />
            {errors && errors.cardNumber &&
              <p className={`accordion-promo__message accordion-promo__message--error`}>
                {errors.cardNumber}
              </p>
            }
          </div>

          <div className="form-group">
            <input
              type="text"
              name="expirationDate"
              className="form-control"
              placeholder="Data di scadenza"
              pattern="\d\d/\d\d"
              autoComplete="cc-exp"
              required
              value={formatExpirationDate(formData.expirationDate)}
              onChange={handleInputChange}
            />
            {errors && errors.expirationDate &&
              <p className={`accordion-promo__message accordion-promo__message--error`}>
                {errors.expirationDate}
              </p>
            }
          </div>

          <h3>Indirizzo di fatturazione</h3>

          <div className="form-group">
            <input
              type="text"
              name="address1"
              className="form-control"
              placeholder="Indirizzo 1"
              autoComplete="shipping billing street-address"
              required
              onChange={handleInputChange}
            />
            {errors && errors.address1 &&
              <p className={`accordion-promo__message accordion-promo__message--error`}>
                {errors.address1}
              </p>
            }
          </div>

          <div className="form-group">
            <input
              type="text"
              name="address2"
              className="form-control"
              placeholder="Indirizzo 2 (opzionale)"
              onChange={handleInputChange}
            />
          </div>

          <div className="form-group">
            <input
              type="text"
              name="postalCode"
              className="form-control"
              placeholder="CAP"
              autoComplete="postal-code"
              required
              onChange={handleInputChange}
            />
            {errors && errors.postalCode &&
              <p className={`accordion-promo__message accordion-promo__message--error`}>
                {errors.postalCode}
              </p>
            }
          </div>

          <div className={'select-container form-group'}>
            <Select<SelectOption>
              isSearchable
              placeholder="Seleziona la provincia"
              options={provinces.map((p) => new SelectOption(p, `${p.name} (${p.code})`))}
              onChange={(option: SelectOption) => {
                handleInputChange({ target: { name: 'province', value: option.value } })
              }} />
            {errors && errors.province &&
              <p className={`accordion-promo__message accordion-promo__message--error`}>
                {errors.province}
              </p>
            }
          </div>

          <div style={{ height: 24 }}></div>
          {/* <div className="form-group">
            <input
              type="text"
              name="province"
              className="form-control"
              placeholder="Provincia"
              required
              onChange={handleInputChange}
            />
            {errors && errors.province &&
              <p className={`accordion-promo__message accordion-promo__message--error`}>
                {errors.province}
              </p>
            }
          </div> */}
        </form>

        <PaymentButton
          extraClass="bank-transfer"
          text={"Paga con carta di credito"}
          onClick={() => handleSubmit(formData)} />
        <div style={{ marginBottom: 8 }} />
        <PaymentButton
          text={"Annulla"}
          onClick={() => onClose()} />
      </div>
    </div>
  );
}

export default CreditCardForm



function clearNumber(value = "") {
  return value.replace(/\D+/g, "");
}


const detectCardIssuer = (cardNumber: string) => {
  const cleanedCardNumber = cardNumber.replace(/\s+/g, ''); // Rimuovi spazi

  // Visa
  if (/^4\d{12}(\d{3})?$/.test(cleanedCardNumber)) {
    return "visa";
  }

  // MasterCard (51-55) or (2221-2720)
  if (/^5[1-5]\d{14}$/.test(cleanedCardNumber) || /^2(22[1-9]|2[3-9]\d|[3-6]\d{2}|7[01]\d|720)\d{12}$/.test(cleanedCardNumber)) {
    return "mastercard";
  }

  // American Express (34 or 37)
  if (/^3[47]\d{13}$/.test(cleanedCardNumber)) {
    return "amex";
  }

  // Discover (6011, 622126-622925, 644-649, 65)
  if (/^6(011|5\d{2}|4[4-9]\d)\d{12}$/.test(cleanedCardNumber) || /^622(12[6-9]|1[3-9]\d|[2-8]\d{2}|9[01]\d|92[0-5])\d{10}$/.test(cleanedCardNumber)) {
    return "discover";
  }

  // Diners Club (300-305, 36, 38)
  if (/^3(0[0-5]\d{11}|[68]\d{12})$/.test(cleanedCardNumber)) {
    return "dinersclub";
  }

  // JCB (3528-3589)
  if (/^35(2[89]|[3-8]\d)\d{12}$/.test(cleanedCardNumber)) {
    return "jcb";
  }

  return "Unknown Card Issuer"; // Se non trova corrispondenze
};

export function formatCreditCardNumber(value: any) {
  if (!value) {
    return value;
  }

  const issuer = detectCardIssuer(value)
  const clearValue = clearNumber(value);
  let nextValue;

  switch (issuer) {
    case "amex":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 10)} ${clearValue.slice(10, 15)}`;
      break;
    case "dinersclub":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 10)} ${clearValue.slice(10, 14)}`;
      break;
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 8)} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 16)}`;
      break;
  }

  return nextValue.trim();
}

export function formatExpirationDate(value: any) {
  const clearValue = clearNumber(value);

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
  }

  return clearValue;
}